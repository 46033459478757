/*
 * File: 404.js
 * Project: meki
 * File Created: Saturday, 16th January 2021 10:06:10 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 27th January 2023 2:44:15 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { ErrorScreen } from '@screens/errors';
import { useOpenReplayTracking } from '@services/openreplay';
import { useEffect } from 'react';
import { useSegment } from '@hooks/useSegment';

export default function NotFound(props) {
  const { trackIssue } = useOpenReplayTracking();
  const { track } = useSegment();
  useEffect(
    function trackError() {
      void trackIssue('404 screen', props);
      void track('Error 404');
    },
    [trackIssue, props, track],
  );
  return <ErrorScreen />;
}
